import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import { Button, Switch, message, Popover } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'
import DrawerForm from '@/components/DrawerForm'
let farmModuleConfigList = []

// 种草模块配置
let recommendModuleConfigList = []

async function onFetchInit() {
  getAction('/config/farmConfigHome/detail').then((res) => {
    const data = res.data
    data.moduleName = '动态管理'

    recommendModuleConfigList = [data]
  })

  const [type1, type2] = await Promise.all(
    ['/farmModuleIcon/list', '/farmModuleConfig/list'].map((e, i) => getAction(e, {}, '/api'))
  )

  type2.data.push({ seve: true })
  farmModuleConfigList = type2.data
  return {
    typeData: {
      type1: type1.data,
      type2: farmModuleConfigList,
      type3: recommendModuleConfigList,
    },
  }
}

function onOpen(form, getDetail) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      form: { ...form },
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      data: (form) => getDrawerForm({ form, getDetail }),
    },
    success: ({ data, setHidden }) => {
      putAction('/farmModuleIcon/update', {
        ...data,
      }).then(() => {
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close,
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

function onSeve() {
  const data = farmModuleConfigList
    .filter((e) => !e.seve)
    .map((e, i) => ({
      ...e,
      sort: i,
    }))

  console.log(data)

  putAction('/farmModuleConfig/updateBatch', data).then(() => {
    message.success('保存成功!')
    setHidden()
  })
}

function getDrawerForm({ form }) {
  const formArr = [
    {
      title: '图标',
      form: [
        {
          labelCol: 24,
          type: 'iconUpload',
          typeData: [
            {
              key: 'iconUrl',
            },
          ],
        },
      ],
    },
  ]
  return formArr
}

export default function ({ getDetail, create }) {
  return {
    type: 'tableGroup',
    params: {
      type: '1',
    },
    onFetchInit,
    data: [
      {
        key: 'type1',
        showHeader: true,
        columns: [
          {
            dataIndex: 'modleName',
            title: '我的设置（图标设置）',
            align: 'left',
            customRender: function customRender(text, records, index, h) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  >
                    {records.iconUrl && (
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        src={records.iconUrl}
                      />
                    )}
                  </div>

                  <span>{text}</span>
                </div>
              )
            },
          },
          {
            width: '80px',
            customRender: function customRender(text, records, index, h) {
              return (
                <div>
                  <Button ghost={true} type="primary" onClick={() => onOpen(records, getDetail)}>
                    编辑
                  </Button>
                </div>
              )
            },
          },
        ],
      },
      {
        dataSource: recommendModuleConfigList,
        styles: {
          marginTop: '20px',
          overflow: 'hidden',
        },
        havedrag: true,
        showHeader: false,
        columns: [
          {
            title: '动态管理（开关设置）',
            dataIndex: 'moduleName',
            align: 'left',
          },
          {
            dataIndex: 'communityState',
            align: 'right',
            customRender: function customRender(text, records, index, h) {
              console.log('text', text)
              return (
                <Switch
                  checked={records.communityState === '1'}
                  onClick={() => {
                    const data = { ...records }
                    if (data.communityState === '1') {
                      data.communityState = '0'
                    } else {
                      data.communityState = '1'
                    }
                    postAction('/config/farmConfigHome/update', data, '/api').then(() => {
                      records.communityState = data.communityState
                    })
                  }}
                />
              )
            },
          },
        ],
      },
      {
        dataSource: farmModuleConfigList,
        styles: {
          marginTop: '20px',
          overflow: 'hidden',
        },
        havedrag: true,
        showHeader: true,
        columns: [
          {
            title: '我的设置（开关设置）',
            dataIndex: 'moduleName',
            align: 'left',

            customRender: function customRender(text, records, index, h) {
              if (records.seve) return
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      marginRight: '20px',
                    }}
                  >
                    <FormUpload
                      item={{}}
                      style={{
                        width: '60px',
                        height: '50px',
                        transform: 'scale(0.5)',
                        marginLeft: '-10px',
                        transformOrigin: 'top',
                      }}
                      value={records.previewUrl}
                      onChange={(data) => {
                        console.log(data, '')
                        records.previewUrl = data
                      }}
                    />
                  </div>

                  <span>{text}</span>
                </div>
              )
            },
          },
          {
            dataIndex: 'status',
            align: 'right',
            customRender: function customRender(text, records, index, h) {
              if (records.seve) return
              return <Switch checked={text === '0'} onClick={() => (records.status = text === '0' ? '1' : '0')} />
            },
          },
          {
            dataIndex: 'sort',
            width: 30,
            align: 'right',
            customRender: function (text, records, index, h) {
              if (records.seve)
                return (
                  <Button ghost={true} type="primary" onClick={onSeve}>
                    保存
                  </Button>
                )
              return <a-icon type="menu" />
            },
          },
        ],
      },
    ],
  }
}
